import { StyledSvg } from './icon.styles.js'

function Piano(props: { className?: string }) {
  const { className, ...restProps } = props
  return (
    <StyledSvg className={className} {...restProps} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M21 4H3c-1.105 0-2 .895-2 2v12c0 1.105.895 2 2 2h18c1.105 0 2-.895 2-2V6c0-1.105-.895-2-2-2zm0 14H3v-7h1v5h2v-5h1v5h2v-5h3v5h2v-5h1v5h2v-5h1v5h2v-5h1z" />
    </StyledSvg>
  )
}

export default Piano
